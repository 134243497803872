<template>
  <w-tables
      :noDataTitle="$t('table.no_data_title')"
      :noDataSubtitle="$t('table.no_data_subtitle')"
      :items="order"
      :fields="tableFields"
      :custimized-items="[
                { name: 'name' },
                { name: 'price' },
                { name: 'total' },
                { name: 'size' },
                { name: 'type' },
                { name: 'addons' },
                { name: 'quantity' },
              ]"
  >
    <template slot="name" slot-scope="{ data }">
      <name-tile
          :name="data.item.name"
          :image="data.item.image"
      />
    </template>


    <template slot="size" slot-scope="{ data }">
      <check-value :value="data.item.size"/>
    </template>

    <template slot="type" slot-scope="{ data }">
      <check-value :value="data.item.type"/>
    </template>

    <template slot="addons" slot-scope="{ data }">
      <check-value :value="data.item.addons"/>
    </template>


    <template slot="price" slot-scope="{ data }">
      <check-price
          :price="data.item.price"
          :dis-price="data.item.dis_price"
      />
    </template>

    <template slot="total" slot-scope="{ data }">
      <check-price
          :price="data.item.total"
      />
    </template>

    <template slot="quantity" slot-scope="{ data }">
      x{{ data.item.quantity }}
    </template>


  </w-tables>
</template>

<script>


import NameTile from "@/components/nameTile.table";
import CheckPrice from "@/views/pages/products/components/checkPrice";
import CheckValue from "@/views/pages/products/components/checkValue";
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {
    CheckValue,
    CheckPrice,
    NameTile,
    WTables,
  },
  props: {
    order: []
  },
  data() {
    return {
      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('products.table.name'),
          sortable: false,
          tdClass: 'w-3/8'
        },

        {
          key: 'size',
          label: this.$i18n.t('products.table.sizes'),
          sortable: false,
        },


        {
          key: 'type',
          label: this.$i18n.t('products.table.types'),
          sortable: false,
        },

        {
          key: 'addons',
          label: this.$i18n.t('products.table.addons'),
          sortable: false,
        },
        {
          key: 'quantity',
          label: this.$i18n.t('table.quantity'),
          sortable: false,
        },
        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: false,
          tdClass: 'w-120px'
        },

        {
          key: 'total',
          label: this.$i18n.t('table.total'),
          sortable: false,
          tdClass: 'w-120px'
        },

      ],
    };
  },


}
;
</script>
