<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('orders.detail')"
        :paths="pagePath()"
    />
    <section class="wameed-dashboard-page-content_body products_create order-detail">
      <div class="order-detail--card mb-5">
        <div class="order-detail-info">


          <div class="flex">
            <div class="tile-text">
              <div class="text">{{ $t('orders.table.no') }}</div>
              <div class="value">#{{ detail.no }}</div>
            </div>
            <div class="tile-text">
              <div class="text">{{ $t('orders.table.date') }}</div>
              <div class="value">{{ formatDate(detail.date) }}</div>
            </div>
          </div>
          <div class="divider"/>
          <div class="flex">
            <div class="tile-text">
              <div class="text">{{ $t('table.price') }}</div>
              <div class="value">{{ formatNumber(detail.total_price) }}</div>
            </div>
            <div class="tile-text">
              <div class="text">{{ $t('orders.table.status') }}</div>
              <div class="value">
                <custom-badge :text="detail.status_name" :color="detail.status_color"/>
              </div>
            </div>
          </div>
          <div class="divider"/>

          <div v-if="detail.cancel_note">
            <div class="tile-text col">
              <div class="text">{{ $t('orders.table.cancel_reason') }}</div>
              <div class="value">{{ detail.cancel_note }}</div>
            </div>

          </div>
          <div v-else style="margin-right:auto">

            <div class="order-detail-btns " v-if="detail.status_type!=null && detail.status_type==0">
              <wameed-btn
                  variant="success"
                  size="sm"
                  :title="$t('orders.accept')"
                  classes="text-book-18  rounded-12   px-4 success-btn"
                  block
                  @onClick="accept"
              />
              <wameed-btn
                  variant="danger"
                  size="sm"
                  :title="$t('orders.cancel')"
                  classes="text-book-18  rounded-12   px-4"
                  block

                  @onClick="showCancelModal=true"
              />
            </div>

          </div>
        </div>

      </div>

      <b-card
          no-body
          class="cursor-pointer wameed-card mb-5"
          v-if="detail.review"
      >
        <div class="">

          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('table.rating') }}
            </h5>
          </b-card-header>

          <div class="order-detail-info p-5">


            <div class="flex">
              <div class="tile-text">
                <div class="text">اسم العميل</div>
                <div class="value">
                  {{ detail.review.name }}
                </div>
              </div>
              <div class="tile-text">
                <div class="text">رقم الهاتف</div>
                {{ detail.review.phone }}
              </div>
            </div>
            <div class="divider"/>
            <div class="flex">
              <div class="tile-text">
                <div class="text">{{$t('table.rating')}}</div>
                <div class="value">
                  <customer-rate :rate="detail.review.rate"/>
                </div>
              </div>
              <div class="tile-text">
                <div class="text">{{$t('table.rated_at')}}</div>
                {{ formatDate(detail.review.date) }}
              </div>
            </div>

            <div class="divider"/>
            <div>
              <div class="tile-text col">
                <div class="text">{{$t('table.comment')}}</div>
                <div class="value">{{ detail.review.comment }}</div>
              </div>


            </div>
          </div>
        </div>
      </b-card>


      <div class="order-detail-table">
        <b-card
            no-body
            class="wameed-card"
        >
          <b-card-header class="align-items-start tab-title">
            <h5 class="text-med-18 text-font-main">
              {{ $t('orders.detail') }}
            </h5>
          </b-card-header>

          <div class="w-table ">

            <order-table
                v-if="detail.order"
                :order="detail.order"
                :tableFields="detail.tableFields"

            />

            <div class="p-5 d-flex justify-content-between">
              <div></div>
              <div class="bill-detail px-4 ">
                <bill-price-tile
                    v-if="detail.vendorId"
                    :data="detail.bill.order"
                    :title="$t('orders.bill.order')"
                />

                <bill-price-tile
                    v-if="detail.vendorId"
                    :data="detail.bill.service"
                    :title="$t('orders.bill.service')"
                    type="service"
                />

                <bill-price-tile
                    v-if="detail.service_is_booking==0"
                    :data="detail.bill.delivery"
                    :title="$t('orders.bill.delivery')"
                />
                <bill-price-tile
                    v-if="detail.vendorId"
                    :data="detail.bill.coupon"
                    :title="$t('orders.bill.coupon')"
                    type="coupon"
                />

                <div class="h-divider"></div>
                <bill-price-tile
                    :data="{price:detail.total_price}"
                    :title="$t('table.total')"
                    type="total"
                />
              </div>
            </div>

            <div class="order-note" v-if="detail.note">
              <div class="text-reg-16 mb-2">{{$t('orders.customer_note')}}</div>
              <div class="text-reg-16 box danger"> {{ detail.note }}</div>
            </div>
            <div class="order-note" v-if="detail.images">
              <div class="text-reg-16 mb-2">{{$t('orders.images')}}</div>
              <div class="images-box">

                <a target="_blank" v-for="(image,index) in detail.images" :key="index"  :href="image">
                  <img class="order-image" :src="image" alt="">
                </a>

              </div>
            </div>
          </div>
        </b-card>
      </div>
    </section>

    <cancel-order-modal
        :visible="showCancelModal"
        @close="showCancelModal = false"
        :order="detail.id"
        @onComplete="()=>loadData()"
    ></cancel-order-modal>

  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm'

import BillPriceTile from "@/views/pages/orders/components/billPriceTile";
import OrderTable from "@/views/pages/orders/components/orderTable";
import CancelOrderModal from "@/views/pages/orders/components/cancelOrderModal";
import CustomerRate from "@/components/customerRate";
import CustomBadge from "@/components/customBadge";

export default {
  components: {
    CustomBadge,
    CustomerRate,
    CancelOrderModal,
    OrderTable,
    BillPriceTile,
    WameedBtn,
    PageHeader,
  },
  props: {
    path: Array
  },
  data() {
    return {
      showCancelModal: false,
      showEditAddressModal: false,
    }
  },

  computed: {
    ...mapGetters({
      detail: 'vendor/orders/getDetail',
    }),

  },
  methods: {
    loadData() {
      this.$store.dispatch("vendor/orders/detail", this.$route.params.id);
    },
    pagePath() {
      if (this.path) {
        return this.path
      }
      return [{title: this.$i18n.t('orders.title'), link: 'orders'}];
    },
    accept() {
      this.$store.dispatch("vendor/orders/accept", this.detail.id).then(() => {
            this.loadData();
          }
      );
    },
  },
  created() {
    this.loadData();
  },
};
</script>
