var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"items":_vm.order,"fields":_vm.tableFields,"custimized-items":[
              { name: 'name' },
              { name: 'price' },
              { name: 'total' },
              { name: 'size' },
              { name: 'type' },
              { name: 'addons' },
              { name: 'quantity' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('name-tile',{attrs:{"name":data.item.name,"image":data.item.image}})]}},{key:"size",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.size}})]}},{key:"type",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.type}})]}},{key:"addons",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.addons}})]}},{key:"price",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":data.item.price,"dis-price":data.item.dis_price}})]}},{key:"total",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":data.item.total}})]}},{key:"quantity",fn:function(ref){
            var data = ref.data;
return [_vm._v(" x"+_vm._s(data.item.quantity)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }